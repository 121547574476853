import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "The Narcissist",
        artist: "Blue",
        coverUrl:
            "https://api.audiohut.ackr8.com/Rock/1.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Rock/1.jpg",
        audio: "https://api.audiohut.ackr8.com/Rock/1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Let Me Love You",
        artist: "DJ Snake ft.Justin Bieber",
        coverUrl:
            "https://api.audiohut.ackr8.com/Rock/2.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Rock/2.jpg",
        audio: "https://api.audiohut.ackr8.com/Rock/2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "Electric Touch",
        artist: "Taylor Version",
        coverUrl:
            "https://api.audiohut.ackr8.com/Rock/3.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Rock/3.jpg",
        audio: "https://api.audiohut.ackr8.com/Rock/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Cheap Thrills",
        artist: "Sia",
        coverUrl:
            "https://api.audiohut.ackr8.com/Rock/4.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Rock/4.png",
        audio: "https://api.audiohut.ackr8.com/Rock/4.mp3",
        palette: "green",
        id: uuidv4(),
    },

    {
        title: "Just Like Heaver",
        artist: "The Cure",
        coverUrl:
            "https://api.audiohut.ackr8.com/Rock/5.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Rock/5.jpg",
        audio: "https://api.audiohut.ackr8.com/Rock/5.mp3",
        palette: "purple",
        id: uuidv4(),
    },
];

export default songData;